import { Component, OnInit,ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
import { Renderer2 } from '@angular/core';
import { Observable,of, Subject } from 'rxjs';
import { map,debounceTime,distinctUntilChanged,tap,switchMap,catchError } from 'rxjs/operators';
declare var $: any;
declare var iziToast: any;
import { PipeTransform, Pipe } from '@angular/core';
import Swal from 'sweetalert2';
@Pipe({
	name: 'callback',
	pure: false
})
@Component({
  selector: 'app-internal-email',
  templateUrl: './internal-email.component.html',
  styleUrls: ['./internal-email.component.css']
})
export class InternalEmailComponent implements OnInit {
  @ViewChild('agent_search') inputName; 
	@ViewChild('row_search') row_count; 
  public text$: Observable<any>;
  public input$ = new Subject<string | null>();
	constructor(private serverService: ServerService, private router: Router, private rd: Renderer2) {


		this.serverService.changeDetectionEmitter.subscribe(
			($event) => {
				// alert('asas');
				let mData = JSON.parse($event);
				var pagefor = mData.pagefor;
				var pageid = mData.id;

				if (pagefor == 'internal_email') {
					this.my_externaltickets();
				}
			},
			(err) => {
			}
		);
	}
	queue_list;
	queue_list_all;
	priority;
	priority_active;
	department_active;
	department;
	status_active;
	status;
	filterlist_status;
	access_token;
	user_id;
	agents_list;
	admin_type;
	admin_id;
	color = ['#00FFFF', '#d4b19d', '#aad47b', '#6c87f5', '#d264e3', '#e67ab5', '#5d1ce8'];
	closed = false;
	emptyticket = false;
	showtickets = false;
	filter_status = 'All';
	filter_depart = 'All';
	filter_agents = 'All';
	paginationData: any = { "info": "hide" };
	pageLimit:number = 10;
	offset_count:number = 0;
	filter_offset = 0;
	total_offet;
	total_offset_filter;
	showmore_button = false;
	showmore_filter = false;
	user_type;
	bulk_tickets;
	select_status = "Select Status";
	select_depart = "Select Department";
	select_agent = "Select Agent";
	view_name = 'Table View';
	status_all;
	global_search=false;
	search_ticket=false;
	searched_value;
	admin_permission;
	delete_tickets;
	isDisabled : boolean;
	agents_list_shared;
	round_robin;
	RowfilterON=false;
  my_email_id;
	changeColour(i, someVar) {
		this.rd.setStyle(someVar, 'background-color', this.color[i]);
	}
	ngOnInit() {
		this.admin_id = localStorage.getItem('admin_id');
		this.user_id = localStorage.getItem('userId');
		this.user_type = localStorage.getItem('user_type');
		this.my_email_id = localStorage.getItem('email_id');
		// this.admin_type = localStorage.getItem('user_type');
		this.admin_permission = localStorage.getItem('admin_permision');
		this.round_robin = localStorage.getItem('round_robin');

		if (this.user_type == 'Super Admin') {
			this.user_type = 1;
		}
		else if (this.user_type == 'Admin' || this.admin_permission =='1') {
			this.user_type = 2;
			this.admin_type='Admin';
		}
		else {
			this.user_type = 3;
			this.admin_type='';

		}
		if(this.admin_permission =='1') 
		   this.user_id = localStorage.getItem('admin_id');


		$(document).ready(function () {
			$(".dropdown-title").click(function () {
				$(".pulldown ").toggleClass("active");
			});
			$(".pulldown a").click(function () {
				alert($(this).text());
			})
		});
		this.access_token = localStorage.getItem('access_token');
		this.my_externaltickets();
		// this.user_lists();
		// this.getuserlist();
		this.changeMylayout('table');
		// this.sharedAgents();
	}


	searchTickets(data) {
		console.log(data);
		// {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"searchTicketID","user_type":"3","admin_id":"1203","user_id":"1250","is_spam":"0","ticket_search":"507","limit":"10","offset":"0"}}


		let search_value = data.search_text;
		this.searched_value = data.search_text;
	//  RESETING all Filters
 	 this.filter_status = 'All';
	 this.filter_depart = 'All';
     this.filter_agents = 'All';

     this.select_status = "Select Status";
    this.select_depart = "Select Department";
	this.select_agent = "Select Agent";
		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',


		});
if(this.searched_value =='' ||this.searched_value ==null ){
	// this.offset_count=0;
this.my_externaltickets();
return false;
	}

		let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"searchTicketID","user_type":"' + this.user_type + '","user_id":"' + this.user_id + '","admin_id":"' + this.admin_id + '","ticket_search":"' + search_value + '","limit":"' + this.pageLimit + '", "is_spam":"0" ,"offset":"' + this.offset_count + '"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
	this.filter_status = 'All';
	 this.filter_depart = 'All';
     this.filter_agents = 'All';

     this.select_status = "Select Status";
    this.select_depart = "Select Department";
	this.select_agent = "Select Agent";
			Swal.close();
         this.global_search=false;
   
			if (response.status == "true") {
				this.queue_list = response.ticket_options;
				this.queue_list_all = response.ticket_options;
				// this.offset_count=response.ticket_options.length>=10?response.ticket_options.length-10:response.ticket_options.length;
				if (this.queue_list == null) {

					this.emptyticket = true;
					this.showtickets = false;
				} else {
					this.emptyticket = false;
					this.showtickets = true;

				}

				this.priority = response.priority_options;
				this.department = response.department_options;
				this.status = response.status_options.filter(t => t.status_id != '9' && t.status_id != '3');
				// this.status = this.status.filter(t => t.status_id != '3');
				// this.filterlist_status = response.status_options;
				// this.status_all = response.count_options;
				this.total_offet = response.total;
				// localStorage.setItem('ticket_status',this.status);
				// localStorage.setItem('priority_options',this.priority);
				// localStorage.setItem('department_options',this.department);
				if (response.status_option == 'closed') {
					$("#dropdown-toggle").prop("disabled", true);
					this.closed = true;
				}
				console.log(this.priority);
				this.showmore_filter=false;
				this.showmore_button = false;
		        this.search_ticket =false;

				if (response.total > this.pageLimit) {
					// alert(this.queue_list_all.length)
					// this.showmore_button = true;
					this.search_ticket=true;

				}
			
			}


		},
			(error) => {
				console.log(error);
			});


	}
	filterSomething(filterArgs: any[]) {
		const firstArg = filterArgs[0];
		const secondArg = filterArgs[1];

		return firstArg;
	}
	my_externaltickets() {
		// this.inputName.nativeElement.value = ' ';
		this.offset_count=0;
		let admin_id = localStorage.getItem('admin_id');
		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		    showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',


		});

		let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"my_internalMail","user_id":"' + this.user_id + '","admin_id":"' + admin_id + '","agent_email":"' +this.my_email_id+ '","limit":"' + this.pageLimit + '", "is_spam":"0" ,"offset":"' + this.offset_count + '"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			Swal.close();
			this.search_ticket=false;// Search option Readmore button
            this.global_search=false;//This will enable for the global search
	     	

			if (response.status == "true") {				
				this.queue_list = response.ticket_options;
				this.queue_list_all = response.ticket_options;
				if (this.queue_list == null) {
					this.emptyticket = true;
					this.showtickets = false;
				} else {
					this.emptyticket = false;
					this.showtickets = true;
				}
				this.total_offet = response.total;			
				this.total_offset_filter=response.total;
				if (response.total > this.pageLimit) {
					this.showmore_button = true;
				}
				else{
					this.showmore_button = false;
				}
	       


			}
		},
			(error) => {
				console.log(error);
			});
	}

	viewMyTicket(ticket_id) {
		ticket_id = btoa(ticket_id);

		this.router.navigate(['/internal-email-view'], { queryParams: { ticket_id: ticket_id } });

	}

	slectAll() {
		if ($("#selectAllQ").prop("checked")) {
			$(".emailtickets").prop("checked", true);
		} else {
			$(".emailtickets").prop("checked", false);
		}
	}
	slectunique(id) {
		$("#selectAllQ").prop("checked", false);
	     
// 	console.log(id)
// 	if(this.admin_id != this.user_id){
// 	       	$(".emailtickets").prop("checked", false);
// setTimeout(() => {
// 	if(this.view_name == 'Table View')
// 	$('#Check_'+id).prop("checked",true)
// 	else
// 	$('#Check_Crd'+id).prop("checked",true)

// }, 100);
// }	 
	}



	changeMylayout(theme) {
		if (theme == 'table') {
			$('#table_view').show();
			$('#card-ticket-view').hide();
			$(".emailtickets").prop("checked", false);
			this.view_name = 'Table View';
		}
		else {
			$('#table_view').hide();
			$('#card-ticket-view').show();
			$(".emailtickets").prop("checked", false);
			this.view_name = 'Card View';

		}

	}



	callFunction(tic) {
		$('#ticket_' + tic).unbind('click');
	}

	showmore() {
		// $("html, body").animate({ scrollTop: $(document).height() }, "slow");
		// $('.ticketing-system-panel').scrollTop($('.ticketing-system-panel')[0].scrollHeight);
		let admin_id = localStorage.getItem('admin_id');
		this.search_ticket =false;
		if (this.filter_agents != 'All') {
			if(this.filter_offset==0){	
				// alert('true')		
				if(!isNaN(Number(this.pageLimit))){
					this.filter_offset = Number(this.pageLimit);
				  }
				   else
					 this.filter_offset = this.pageLimit;
			}
			else
			this.filter_offset = this.filter_offset + 10;		
			var offset = this.filter_offset;
				// alert(this.filter_offset);
			if (this.total_offset_filter > offset + 10) {
				if (this.filter_offset >= this.total_offset_filter) {
					this.filter_offset = this.total_offset_filter;
					this.showmore_filter = false;
					this.showmore_button = false;
				}
			} else {
				// alert('filter');
				this.showmore_filter = false;
				this.showmore_button = false;
			}
			this.filterByAgentshowmore();
			return false;
		} 
		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',


		});

		this.showmore_button = true;
		if(this.offset_count==0){			
			if(!isNaN(Number(this.pageLimit))){
				this.offset_count = Number(this.pageLimit);
			  }
			   else
	         	this.offset_count = this.pageLimit;
		}
        else
		this.offset_count = this.offset_count +10;
		// alert(this.offset_count);
		// this.offset_count = this.offset_count -this.total_offet;
		var offset = this.offset_count;	
	
		if (this.total_offet > offset + 10) {
			if (this.offset_count >= this.total_offet) {
				this.offset_count = this.total_offet;
				this.showmore_button = false;
			}
		} else {
			this.showmore_button = false;
		}
		if (this.filter_agents != 'All') {
			this.user_type = '3';
			this.user_id = this.filter_agents;
		}
		let api_req: any = '{"operation":"getmyExternalTicket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"my_externaltickets","user_type":"' + this.user_type + '","user_id":"' + this.user_id + '","admin_id":"' + admin_id + '","ticket_status":"' + this.filter_status + '","is_spam":"0","limit":"' + this.pageLimit + '","offset":"' + this.offset_count + '","ticket_department":"' + this.filter_depart + '","ticket_user":"' + this.user_id +'"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			this.search_ticket=false;// Search option Readmore button
            this.global_search=false;//This will enable for the global search
			Swal.close();
			if (response.status == "true") {
				// this.queue_list = response.ticket_options;
				// this.queue_list_all = response.ticket_options;

				var mydatas = [];
				mydatas = response.ticket_options;
				// alert(mydatas.length);		
				// this.queue_list = this.queue_list_all.push(mydatas); 
				for (let index = 0; index < mydatas.length; index++) {
					var data = mydatas[index];
					this.queue_list.push(data);
				}

			}
		},
			(error) => {
				console.log(error);
			});
	}

	showmoreFilter() {
		this.showmore_filter = true;
		this.filter_offset = this.filter_offset + 10;
		// alert(this.offset_count);
		// this.offset_count = this.offset_count -this.total_offet;
		let admin_id = localStorage.getItem('admin_id');
		var offset = this.filter_offset;
		if (this.total_offset_filter >= offset + 10) {
			if (this.filter_offset >= this.total_offset_filter) {
				this.filter_offset = this.total_offset_filter;
				this.showmore_filter = false;
			}
		} else {
			// alert('filter');
			this.showmore_filter = false;
		}
		if (this.filter_agents != 'All') {
			this.user_type = '3';
			this.user_id = this.filter_agents;
		}

		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',


		});

		let api_req: any = '{"operation":"getmyExternalTicket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"my_externaltickets","user_type":"' + this.user_type + '","user_id":"' + this.user_id + '","admin_id":"' + admin_id + '","ticket_status":"' + this.filter_status + '","is_spam":"0" ,"limit":"' + this.pageLimit + '","offset":"' + this.filter_offset + '","ticket_department":"' + this.filter_depart + '","ticket_user":"' + this.user_id + '"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			Swal.close();
			this.search_ticket=false;// Search option Readmore button
            this.global_search=false;//This will enable for the global search
			if (response.status == "true") {

				// this.queue_list = response.ticket_options;
				// this.queue_list_all = response.ticket_options;
				var mydatas = [];
				mydatas = response.ticket_options;
				// alert(mydatas.length);		
				// this.queue_list = this.queue_list_all.push(mydatas); 
				for (let index = 0; index < mydatas.length; index++) {
					var data = mydatas[index];
					this.queue_list.push(data);
				}

			}
		},
			(error) => {
				console.log(error);
			});

	}	
	showmoreSearch() {
		let admin_id = localStorage.getItem('admin_id');	
		this.filter_status = 'All';
     	this.filter_depart = 'All';
	    this.filter_agents = 'All';
		this.select_status = "Select Status";
	    this.select_depart = "Select Department";
     	this.select_agent = "Select Agent";
		 $(".AgentCheckDefault").prop("checked", false);

		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',


		});

		this.search_ticket = true;
		this.offset_count = this.offset_count + 10;	
		// this.offset_count = this.offset_count -this.total_offet;
		var offset = this.offset_count;
		if (this.total_offet >= offset + 10) {
			if (this.offset_count >= this.total_offet) {
				this.offset_count = this.total_offet;
				this.search_ticket = false;
			}
		} else {
			// alert('dasds');
			this.search_ticket = false;
		}		
if(this.global_search ==true){
var ser_user_id=this.admin_id;
var ser_user_type='2';
}
else{
	 ser_user_id=this.user_id;
     ser_user_type=this.user_type;
}
		// let api_req: any = '{"operation":"getmyExternalTicket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"my_externaltickets","user_type":"' + this.user_type + '","user_id":"' + this.user_id + '","admin_id":"' + admin_id + '","ticket_status":"' + this.filter_status + '","is_spam":"0","limit":"' + this.pageLimit + '","offset":"' + this.offset_count + '","ticket_department":"' + this.filter_depart + '"}}';
		let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"searchTicketID","user_type":"' + ser_user_type + '","user_id":"' + ser_user_id + '","admin_id":"' + this.admin_id + '","ticket_search":"' + this.searched_value + '","limit":"' + this.pageLimit + '", "is_spam":"0" ,"offset":"' + this.offset_count + '"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			
			Swal.close();	          
			if (response.status == "true") {
				// this.queue_list = response.ticket_options;
				// this.queue_list_all = response.ticket_options;

				var mydatas = [];
				mydatas = response.ticket_options;
				// alert(mydatas.length);		
				// this.queue_list = this.queue_list_all.push(mydatas); 
				for (let index = 0; index < mydatas.length; index++) {
					var data = mydatas[index];
					this.queue_list.push(data);
				}

			}
		},
			(error) => {
				console.log(error);
			});
			
	}

	DeleteTickets(){
		var i = 0;
		var invalidContacts = [];
		$('.emailtickets:checked').each(function () {
		  invalidContacts[i++] = $(this).val();
		}); 
		
	  console.log(invalidContacts.length);
	  if(invalidContacts.length==0){
		iziToast.warning({
		  message: "Please Choose a Ticket",
		  position: 'topRight'
	  });
	  return false;
	  }
	  
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You won't be able to revert this!",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
		  if (result.value) {
		  let access_token: any=localStorage.getItem('access_token');
		 

		//   let api_req:any = '{"operation":"predective_dialer_contact", "moduleType":"predective_dialer_contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"delete_contact","user_id":"'+this.user_id+'","contact_id":"'+invalidContacts+'"}}';
		  let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"delete_multiple_ticket","value":"' + invalidContacts + '","admin_id":"' + this.admin_id + '"}}';
		
		  this.serverService.sendServer(api_req).subscribe((response:any) => {
	  
			console.log(response);
			if(response.result.data==true){
			  iziToast.success({
				message: "Ticket deleted successfully",
				position: 'topRight'
			});					
			this.my_externaltickets();
			} else {
			  iziToast.warning({
				message: "Contact not deleted, Please try again!",
				position: 'topRight'
			});
			}
		  }, 
		  (error)=>{
			  console.log(error);
		  });
		
	  }  
	  })
	  }
	

	removeDuplicates(originalArray, prop) {
		var newArray = [];
		var lookupObject  = {};
   
		for(var i in originalArray) {
		   lookupObject[originalArray[i][prop]] = originalArray[i];
		}
   
		for(i in lookupObject) {
			newArray.push(lookupObject[i]);
		}
		 return newArray;
	}

	offsetValue(val:number){
			
	if(val > this.total_offset_filter){
		iziToast.warning({
			message:"You have a maximum of "+this.total_offset_filter+" rows",
			position:"topRight"
		});
		return false;
	}
	if(val > 50){
		iziToast.warning({
			message:"Maximum Row limit was 50",
			position:"topRight"
		});
		return false;
	}
	$('#drop_down2').click();	
		this.pageLimit=val;
		// this.offset_count=0;
		this.my_externaltickets();		
		this.RowfilterON=true;

	}

	  resetRowCount(){
		  this.RowfilterON=false;
		  this.pageLimit=10;
		  this.my_externaltickets();	
	  }

	  filterByAgentshowmore(){
		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',


		});

	// 	if(this.filter_offset >= this.total_offet){
	// 		this.filter_offset = this.total_offet;
	this.showmore_filter = false;
	// 	//   return false;
	//    }
// alert(this.filter_offset)
//This is BF 17-08-2021 BF shareMYticket	// let api_req: any = '{"operation":"getmyExternalTicket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"my_externaltickets","user_type":"3", "is_spam":"0" ,"user_id":"' + this.filter_agents + '","admin_id":"' + admin_id + '","ticket_department":"' + this.filter_depart + '","limit":"' + this.pageLimit + '","offset":"' + this.filter_offset + '","ticket_status":"' + this.filter_status + '"}}';
	let api_req: any = '{"operation":"filter_getmyExternalTicket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"filter_getmyExternalTicket","user_type":"3", "is_spam":"0" ,"user_id":"' + this.filter_agents + '","admin_id":"' + this.admin_id + '","ticket_department":"' + this.filter_depart + '","limit":"' + this.pageLimit + '","offset":"' + this.filter_offset + '","ticket_status":"' + this.filter_status + '","ticket_user":"' + this.filter_agents + '"}}';
	this.serverService.sendServer(api_req).subscribe((response: any) => {	
		Swal.close();
		this.search_ticket=false;// Search option Readmore button
		this.global_search=false;//This will enable for the global search
		if (response.status == "true") {

			// this.queue_list = response.ticket_options;
			// this.queue_list_all = response.ticket_options;
			var mydatas = [];
			mydatas = response.ticket_options;
			// alert(mydatas.length);		
			// this.queue_list = this.queue_list_all.push(mydatas); 
			for (let index = 0; index < mydatas.length; index++) {
				var data = mydatas[index];
				this.queue_list.push(data);
			}

		}
	},
		(error) => {
			console.log(error);
		});
	  }
}
